'use strict';

import $ from 'jquery';
import 'jquery.easing';
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints';
import 'featherlight';

var common = (function() {
    var ua = window.navigator.userAgent.toLowerCase();
    var ver = window.navigator.appVersion.toLowerCase();

    var breakPoint = 768;

    var getBrowser = function() {
        var name = 'unknown';

        if (ua.indexOf('msie') !== -1) {
            if (ver.indexOf('msie 6.') !== -1) {
                name = 'ie6';
            } else if (ver.indexOf('msie 7.') !== -1) {
                name = 'ie7';
            } else if (ver.indexOf('msie 8.') !== -1) {
                name = 'ie8';
            } else if (ver.indexOf('msie 9.') !== -1) {
                name = 'ie9';
            } else if (ver.indexOf('msie 10.') !== -1) {
                name = 'ie10';
            } else {
                name = 'ie';
            }
        } else if (ua.indexOf('trident/7') !== -1) {
            name = 'ie11';
        } else if (ua.indexOf('chrome') !== -1) {
            name = 'chrome';
        } else if (ua.indexOf('safari') !== -1) {
            name = 'safari';
        } else if (ua.indexOf('opera') !== -1) {
            name = 'opera';
        } else if (ua.indexOf('firefox') !== -1) {
            name = 'firefox';
        }

        return name;
    };

    var getDevice = function() {
        var name = 'unknown';

        if (
            ua.indexOf('iphone') > 0 ||
            ua.indexOf('ipod') > 0 ||
            (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
        ) {
            name = 'mobile';
        } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            name = 'tablet';
        } else {
            name = 'pc';
        }

        return name;
    };

    var getQuery = function(query) {
        var querys = location.search.substring(1),
            vars = querys.split('&'),
            i;

        for (i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === query) {
                return pair[1];
            }
        }
    };

    var getRandom = function(min, max) {
        var random = Math.floor(Math.random() * Math.random() * Math.random() * (max + 1 - min)) + min;

        return random;
    };

    var moveTo = function(id, loaded, e) {
        var duration = loaded ? 100 : 600,
            easing = 'easeInOutQuart',
            target = id.substr(id.indexOf('#'));

        if ($(target).length > 0) {
            if (e) {
                e.preventDefault();
            }

            var diff = $(window).width() >= breakPoint ? 132 : 48;
            var position = $(target).offset().top;
            var offset = position > 0 ? diff : 0;

            $('html, body').animate(
                {
                    scrollTop: $(target).offset().top - offset
                },
                {
                    duration: duration,
                    easing: easing
                }
            );
        }
    };

    var switchImage = function($img) {
        var src = $img.attr('src');
        var w = parseInt($(window).width());

        if (w >= breakPoint) {
            if (src.indexOf('@sp') >= 0) {
                $img.css('opacity', 0);
                src = $img.attr('src').replace('@sp.', '.');
            }
        } else {
            if (src.indexOf('@sp') < 0) {
                $img.css('opacity', 0);
                src = $img.attr('src').replace(/\.(?=(?:png|jpg|svg)$)/i, '@sp.');
            }
        }

        $img.attr('src', src);
        $img.on('load', function() {
            $img.removeAttr('style');
        });
    };

    var inView = function($ele) {
        var inview = new Waypoint({
            element: $ele[0],
            offset: '70%',
            handler: function(direction) {
                $(this.element).addClass('is-inview');
            }
        });
    };

    var onLoad = function() {
        if (location.hash !== '') {
            moveTo(location.hash, true);
        }
    };

    var onResize = function() {
        setTimeout(function() {
            $('.js-switch-image').each(function() {
                switchImage($(this));
            });
        }, 10);
    };

    var onScroll = function() {
        if ($(window).scrollTop() > 50) {
            $('body').addClass('is-scrolled');
        } else {
            $('body').removeClass('is-scrolled');
        }
    };

    var init = function() {
        $('body')
            .imagesLoaded()
            .always(function(instance) {
                console.log('all images loaded');
            })
            .done(function(instance) {
                console.log('all images successfully loaded');

                $('body').addClass('is-loaded');

                $('.js-inview').each(function(i) {
                    inView($(this));
                });

                hamburgerMenu.init();
                gallery.init();

                if ($('#page-home').length > 0) {
                    home.init();
                }

                if ($('#page-factory').length > 0) {
                    factory.init();
                }

                if ($('#page-product').length > 0) {
                    product.init();
                }

                if ($('#page-company').length > 0) {
                    company.init();
                }

                if ($('#page-catalog').length > 0) {
                    catalog.init();
                }
            })
            .fail(function() {
                console.log('all images loaded, at least one is broken');

                $('body').addClass('is-loaded');

                $('.js-inview').each(function(i) {
                    inView($(this));
                });

                hamburgerMenu.init();
                gallery.init();

                if ($('#page-home').length > 0) {
                    home.init();
                }

                if ($('#page-factory').length > 0) {
                    factory.init();
                }

                if ($('#page-product').length > 0) {
                    product.init();
                }

                if ($('#page-company').length > 0) {
                    company.init();
                }

                if ($('#page-catalog').length > 0) {
                    catalog.init();
                }
            })
            .progress(function(instance, image) {
                var result = image.isLoaded ? 'loaded' : 'broken';
                console.log('image is ' + result + ' for ' + image.img.src);
            });

        $('a[href*="#"]').on('click', function(e) {
            var loc = location.pathname;
            var dir = loc.substring(0, loc.lastIndexOf('/')) + '/';
            var url = $(this).attr('href');

            if (dir !== '/') {
                if (url.indexOf(dir) >= 0) {
                    e.preventDefault();
                    moveTo($(this).attr('href'), false);
                }
            }
        });

        $('a[href^="#"]').on('click', function(e) {
            e.preventDefault();
            moveTo($(this).attr('href'), false);
        });

        $('.js-switch-image').each(function() {
            switchImage($(this));
        });

        $(window).on({
            load: onLoad,
            resize: onResize,
            scroll: onScroll
        });
    };

    //public
    return {
        breakPoint: breakPoint,
        getBrowser: getBrowser,
        getDevice: getDevice,
        getQuery: getQuery,
        getRandom: getRandom,
        moveTo: moveTo,
        init: init
    };
})();

var hamburgerMenu = (function() {
    var toggle = function() {
        $('body').toggleClass('is-show-menu');
    };

    var alignHeightMenu = function() {
        var w = parseInt($(window).width());

        if (w >= common.breakPoint) {
            $('#header')
                .find('.nav')
                .removeAttr('style');
        } else {
            var h = $(window).height();

            $('#header')
                .find('.nav')
                .outerHeight(h);
        }
    };

    var onResize = function() {
        setTimeout(function() {
            alignHeightMenu();
        }, 10);
    };

    var init = function() {
        $('.js-toggle-menu').on('click', function() {
            toggle();
        });

        alignHeightMenu();

        $(window).on({
            resize: onResize
        });
    };

    //public
    return {
        init: init
    };
})();

var gallery = (function() {
    var init = function() {
        $('#gallery')
            .find('.list')
            .slick({
                autoplay: true,
                infinite: true,
                initialSlide: 2,
                arrows: false,
                variableWidth: true,
                centerMode: true,
                centerPadding: '0'
            });
    };

    //public
    return {
        init: init
    };
})();

var map = (function() {
    var init = function(id, x, y) {
        var latlng = {
            lat: x,
            lng: y
        };

        var map = new google.maps.Map(document.getElementById(id), {
            center: latlng,
            zoom: 15,
            styles: [
                {
                    elementType: 'geometry',
                    stylers: [{ color: '#f5f5f5' }]
                },
                {
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'off' }]
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#616161' }]
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#f5f5f5' }]
                },
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#bdbdbd' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{ color: '#eeeeee' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#757575' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#e5e5e5' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#ffffff' }]
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#757575' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#dadada' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#616161' }]
                },
                {
                    featureType: 'road.local',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }]
                },
                {
                    featureType: 'transit.line',
                    elementType: 'geometry',
                    stylers: [{ color: '#e5e5e5' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'geometry',
                    stylers: [{ color: '#eeeeee' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'on' }]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#c9c9c9' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }]
                }
            ]
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });
    };

    //public
    return {
        init: init
    };
})();

var sideMenu = (function() {
    var fixed = function() {
        if ($(window).width() >= common.breakPoint) {
            var window_h = $(window).height();
            var menu_y = $('.column__nav').offset().top;
            var menu_h = $('.column__nav').height();
            var gallery_y = $('#gallery').length ? $('#gallery').offset().top : 0;
            var gallery_h = $('#gallery').length ? $('#gallery').height() : 0;
            var footer_y = $('#footer').offset().top;
            var footer_h = $('#footer').height();
            var fix_y = menu_y - 252;
            var max_y = gallery_y > 0 ? gallery_y - window_h - 40 : footer_y - window_h - 40;

            $(window).on('scroll', function() {
                if ($(window).scrollTop() >= fix_y) {
                    $('.column__nav').addClass('is-fixed');
                } else {
                    $('.column__nav').removeClass('is-fixed');
                }

                if (menu_h > window_h - 372) {
                    if ($(window).scrollTop() >= max_y + 80) {
                        $('.column__nav').addClass('is-max');
                    } else {
                        $('.column__nav').removeClass('is-max');
                    }
                } else {
                    if ($(window).scrollTop() >= max_y + window_h - fix_y - 80) {
                        $('.column__nav').addClass('is-max');
                    } else {
                        $('.column__nav').removeClass('is-max');
                    }
                }
            });
        } else {
            var menu_y = $('.column__nav').offset().top;
            var menu_h = $('.column__nav').outerHeight();
            var header_h = $('#header').outerHeight();
            var fix_y = menu_y - header_h;

            $('.column').css({
                'padding-top': menu_h
            });

            $(window).on('scroll', function() {
                if ($(window).scrollTop() >= fix_y) {
                    $('.column__nav').addClass('is-fixed');
                } else {
                    $('.column__nav').removeClass('is-fixed');
                }
            });
        }
    };

    var toggle = function() {
        $('.local-nav__ttl').on('click', function() {
            if ($(window).width() < common.breakPoint) {
                $(this)
                    .next('.local-nav__body')
                    .slideToggle();
            }
        });
    };

    var init = function() {
        if ($('.column').length) {
            fixed();
            toggle();
        }
    };

    //public
    return {
        init: init
    };
})();

var home = (function() {
    var init = function() {
        map.init('map', 37.9115455, 139.0272218);
    };

    //public
    return {
        init: init
    };
})();

var factory = (function() {
    var $carousel = $('#modal').find('.carousel__inner');

    var openModal = function(index) {
        $carousel.slick('slickGoTo', index);
        $('body').addClass('is-open-modal');
    };

    var closeModal = function() {
        $('body').removeClass('is-open-modal');
    };

    var init = function() {
        $('.js-open-modal').each(function(i) {
            $(this).on('click', function(e) {
                e.preventDefault();
                openModal(i);
            });
        });

        $('.js-close-modal').on('click', function() {
            closeModal();
        });

        $carousel.slick({
            fade: true
        });
    };

    //public
    return {
        init: init
    };
})();

var product = (function() {
    var init = function() {
        sideMenu.init();
    };

    //public
    return {
        init: init
    };
})();

var company = (function() {
    var init = function() {
        map.init('map', 37.9115455, 139.0272218);
        sideMenu.init();
    };

    //public
    return {
        init: init
    };
})();

var catalog = (function() {
    var init = function() {
        $('#slide')
            .find('.list')
            .slick({});
    };

    //public
    return {
        init: init
    };
})();

$(function() {
    common.init();
});
